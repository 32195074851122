<div class="cutlist-component">

    <!--
  <button (click)="debugPanel()">Debug Panel</button>
      -->
    <table>
        <tr>
            <td colspan="2">

                <table class="cutlist" (keydown)="tableKey($event)">
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th *ngFor="let colname of columnOrder; let colId = index" class="cutlist-header">
                                <img src="/assets/images/arrow-left.png" class="leftright" (click)="moveColLeft(colId)"
                                    [ngClass]="{'hide-leftright': (colId==0)}" />
                                <ng-container *ngIf="colname=='Qty'"><span title="Quantity">Qty</span></ng-container>
                                <ng-container *ngIf="colname=='FeetInches'"><span title="Feet and Inches">Feet &amp;
                                        Inches</span></ng-container>
                                <ng-container *ngIf="colname=='TotInches'"><span title="Total Inches">Tot.
                                        Inches</span></ng-container>
                                <ng-container *ngIf="colname=='Fractions'">Fraction</ng-container>
                                <img src="/assets/images/arrow-right.png" class="leftright"
                                    (click)="moveColRight(colId)" [ngClass]="{'hide-leftright': (colId==3)}" />
                            </th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let cut of panel.metadata.cutlist; let rowId = index">
                            <tr class="cuts" [ngClass]="(rowId%2==0) ? 'even' : 'odd'">
                                <td class="first-col">
                                    <table class="line-ctrl">
                                        <tbody>
                                            <tr>
                                                <td class="updown"><img (click)="moveCutUp(rowId)"
                                                        src="/assets/images/arrow-up.png"
                                                        [ngClass]="{'hide-updown': cutlistCantMoveUp(cut)}" /></td>
                                            </tr>
                                            <tr>
                                                <td><span class="cut-linenumber">{{cut.Linenumber}}</span></td>
                                            </tr>
                                            <tr>
                                                <td class="updown"><img (click)="moveCutDown(rowId)"
                                                        src="/assets/images/arrow-down.png"
                                                        [ngClass]="{'hide-updown': cutlistCantMoveDown(cut)}" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>

                                <td *ngFor="let colname of columnOrder; let colId = index" class="cutlist-cell">
                                    <ng-container *ngIf="colname=='Qty'">
                                        <input type="text" class="form-control qty" [(ngModel)]="cut.Quantity"
                                            (keyup)="clearPricing()" (ngModelChange)="updateQty(rowId)"
                                            id="cut-qty-{{rowId}}" (focus)="selectInputContents($event)"
                                            (blur)="blurInput($event)" (click)="clearZero($event)" data-fieldid="qty"
                                            [attr.data-colid]="colId" [attr.data-rowid]="rowId">
                                    </ng-container>
                                    <ng-container *ngIf="colname=='FeetInches'">
                                        <span class="input-group">
                                            <input type="text" class="form-control input-feet" [(ngModel)]="cut.Feet"
                                                (keyup)="clearPricing()" (ngModelChange)="updateTot(rowId)"
                                                id="cut-feet-{{rowId}}" (blur)="blurInput($event)"
                                                (focus)="selectInputContents($event)" (click)="clearZero($event)"
                                                data-fieldid="feet" [attr.data-colid]="colId" [attr.data-rowid]="rowId">
                                            <input type="text" class="form-control input-inch inches"
                                                [(ngModel)]="cut.Inches" (keyup)="clearPricing()"
                                                (ngModelChange)="updateTot(rowId)" id="cut-inches-{{rowId}}"
                                                (blur)="blurInput($event)" (focus)="selectInputContents($event)"
                                                (click)="clearZero($event)" data-fieldid="inches"
                                                [attr.data-colid]="colId" [attr.data-rowid]="rowId">
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="colname=='TotInches'">
                                        <input type="text" class="form-control input-inch totinches"
                                            [(ngModel)]="cut.TotalInches" (keyup)="clearPricing()"
                                            (ngModelChange)="updateFI(rowId)" id="cut-totinches-{{rowId}}"
                                            (blur)="blurInput($event)" (focus)="selectInputContents($event)"
                                            (click)="clearZero($event)" data-fieldid="totinches"
                                            [attr.data-colid]="colId" [attr.data-rowid]="rowId">
                                    </ng-container>
                                    <ng-container *ngIf="colname=='Fractions'">
                                        <select class="form-control fractions" id="cut-fractions-{{rowId}}"
                                            (blur)="cutlistChanged($event)" (focus)="cutlistChanged($event)"
                                            (click)="cutlistChanged($event)" [(ngModel)]="cut.Fractions"
                                            data-fieldid="fractions" [attr.data-colid]="colId"
                                            [attr.data-rowid]="rowId">
                                            <option value="0"></option>
                                            <option value="1">&mdash;&nbsp;&nbsp;1/16&quot;</option>
                                            <option value="2">&mdash;&mdash;&nbsp;&nbsp;1/8&quot;</option>
                                            <option value="3">&mdash;&nbsp;&nbsp;3/16&quot;</option>
                                            <option value="4">&mdash;&mdash;&mdash;&nbsp;1/4&quot;</option>
                                            <option value="5">&mdash;&nbsp;&nbsp;5/16&quot;</option>
                                            <option value="6">&mdash;&mdash;&nbsp;&nbsp;3/8&quot;</option>
                                            <option value="7">&mdash;&nbsp;&nbsp;7/16&quot;</option>
                                            <option value="8">&mdash;&mdash;&mdash;&mdash;1/2&quot;</option>
                                            <option value="9">&mdash;&nbsp;&nbsp;9/16&quot;</option>
                                            <option value="10">&mdash;&mdash;&nbsp;&nbsp;5/8&quot;</option>
                                            <option value="11">&mdash;&nbsp;11/16&quot;</option>
                                            <option value="12">&mdash;&mdash;&mdash;&nbsp;3/4&quot;</option>
                                            <option value="13">&mdash;&nbsp;13/16&quot;</option>
                                            <option value="14">&mdash;&mdash;&nbsp;&nbsp;7/8&quot;</option>
                                            <option value="15">&mdash;&nbsp;15/16&quot;</option>
                                        </select>
                                    </ng-container>
                                </td>

                                <td>
                                    <button *ngIf="panel.metadata.cutlist.length > 1" (click)="removeCut(rowId)"
                                        class="btn btn-warning-dark iconBtn">
                                        <fa-icon [icon]="d.faTimes"></fa-icon>
                                    </button>
                                </td>

                            </tr>
                            <tr *ngIf="cut.Errors.length > 0" class="error-row"
                                [ngClass]="(rowId%2==0) ? 'even' : 'odd'">
                                <td class="first-col">&nbsp;</td>
                                <td colspan="5" class="pulse" [innerHTML]="cut.Errors.join(', ')|safe"></td>
                            </tr>
                        </ng-container>
                        <tr class="hintrow">
                            <td colspan="5" class="hinttd">


                                <table class="hints2">
                                    <tr class="legend">
                                        <td><span>Add Cut:</span></td>
                                        <td><span>Delete Cut:</span></td>
                                        <td><span>Move Cut:</span></td>
                                        <td><span>Navigate:</span></td>
                                        <td><span>Fractions:</span></td>
                                    </tr>
                                    <tr>
                                        <td>ENTER</td>
                                        <td>CTRL-DEL</td>
                                        <td>CTRL-<fa-icon [icon]="d.faArrowDown"></fa-icon>/<fa-icon
                                                [icon]="d.faArrowUp"></fa-icon></td>
                                        <td>TAB,<fa-icon [icon]="d.faArrowUp"></fa-icon>,<fa-icon
                                                [icon]="d.faArrowDown"></fa-icon>,<fa-icon
                                                [icon]="d.faArrowLeft"></fa-icon>,<fa-icon
                                                [icon]="d.faArrowRight"></fa-icon></td>
                                        <td>516 = 5/16&quot;</td>
                                    </tr>
                                </table>


                            </td>
                            <td class="btn-cell">
                                <button (click)="addCut()" class="btn btn-primary iconBtn"
                                    *ngIf="panel.metadata.cutlist.length < maxRows"><fa-icon
                                        [icon]="d.faPlus"></fa-icon></button>
                            </td>
                        </tr>
                    </tbody>
                </table>


                <br />
            </td>
        </tr>
        <tr>
            <td class="pack-td">
                <div style="font-weight:bold" [class.pulseError]="!panel.metadata.packaging.PackType">Selected
                    Packaging:</div>

                <div class="btn-group btn-group-vertical" data-toggle="buttons" *ngIf="d.model.packagingTypeSet">
                    <label class="btn btn-default text-left radio-btn" *ngFor="let packType of d.model.packagingTypeSet"
                        [ngClass]="{'active':(panel.metadata.packaging.PackType==packType.PackType), 'disabled':packType.PackDisabled}"
                        (click)="panel.metadata.packaging=packType;checkErrors()">
                        <input type="radio" name="options" id="option1" autocomplete="off" checked>

                        <fa-stack>
                            <fa-icon [icon]="d.faCircleThin" stackItemSize="2x"></fa-icon>
                            <fa-icon [icon]="d.faCircle" stackItemSize="1x" style="color:#00665E"
                                *ngIf="panel.metadata.packaging.PackType == packType.PackType"></fa-icon>
                        </fa-stack>

                        {{packType.PackDescription}}{{packType.PackSuggested}}
                    </label>
                </div>
            </td>
            <td style="vertical-align:top">

                <table>
                    <tr>
                        <td>
                            <button class="btn btn-primary" (click)="pricingSet()"
                                [ngClass]="{'disabled': (cutlistErrorCount > 0 || panel.metadata.pricingSetResult.ItemTotal!='0.00') }"
                                style="float:right">
                                <fa-icon [icon]="d.faSync"></fa-icon>
                                Check Price
                            </button><br />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table class="cutlist-total-table">
                                <tr>
                                    <td>&nbsp;</td>
                                    <td><b>Unit&nbsp;Price</b></td>
                                    <td><b>Total</b></td>
                                </tr>
                                <tr class="sub-total">
                                    <td><b>Price:</b></td>
                                    <td> {{panel.metadata.pricingSetResult.NetPrice|currency:'USD':'symbol':'1.2-2'}}
                                    </td>
                                    <td> {{panel.metadata.pricingSetResult.NetPriceTotal|currency:'USD':'symbol':'1.2-2'}}
                                    </td>
                                </tr>
                                <tr *ngIf="panel.metadata.pricingSetResult.DiscountAmt!='0.00'">
                                    <td><b>Discount:</b></td>
                                    <td>{{panel.metadata.pricingSetResult.DiscountPct|discountpercent}}</td>
                                    <td> {{panel.metadata.pricingSetResult.DiscountAmt|currency:'USD':'symbol':'1.2-2'}}
                                    </td>
                                </tr>
                                <tr
                                    *ngIf="panel.metadata.pricingSetResult.ItemSubTotal!=panel.metadata.pricingSetResult.ItemTotal">
                                    <td colspan="2"><b>Subtotal:</b></td>
                                    <td> {{panel.metadata.pricingSetResult.ItemSubTotal|currency:'USD':'symbol':'1.2-2'}}
                                    </td>
                                </tr>
                                <tr *ngIf="panel.metadata.pricingSetResult.ShortcutExtra!='0.00'">
                                    <td colspan="2"><b>Shortcut Extra:</b></td>
                                    <td> {{panel.metadata.pricingSetResult.ShortcutExtra|currency:'USD':'symbol':'1.2-2'}}
                                    </td>
                                </tr>
                                <tr *ngIf="panel.metadata.pricingSetResult.LLFixExtra!='0.00'">
                                    <td colspan="2"><b>Fixed Long Length Extra:</b></td>
                                    <td> {{panel.metadata.pricingSetResult.LLFixExtra|currency:'USD':'symbol':'1.2-2'}}
                                    </td>
                                </tr>
                                <tr *ngIf="panel.metadata.pricingSetResult.LLVarExtra!='0.00'">
                                    <td colspan="2"><b>Var. Long Length Extra:</b></td>
                                    <td> {{panel.metadata.pricingSetResult.LLVarExtra|currency:'USD':'symbol':'1.2-2'}}
                                    </td>
                                </tr>
                                <tr class="sub-total">
                                    <td colspan="2"><b>Total:</b></td>
                                    <td> {{panel.metadata.pricingSetResult.ItemTotal|currency:'USD':'symbol':'1.2-2'}}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td [attr.title]="(panel.metadata.pricingSetResult.ItemTotal=='0.00')?'You must click Check Price first':''"
                            (click)="notifyCheckPrice()">
                            <button (click)="addToCart($event)" id="cutlistAddCartBtn" *ngIf="semaphore!=-1"
                                class="btn btn-primary" style="float:right"
                                [ngClass]="{'disabled':(panel.metadata.pricingSetResult.ItemTotal=='0.00')}">
                                <fa-icon [icon]="d.faCartPlus"></fa-icon>
                                Add to Cart
                            </button>
                            <button (click)="updateCart($event)" *ngIf="semaphore==-1" class="btn btn-primary"
                                style="float:right"
                                [ngClass]="{'disabled':(panel.metadata.pricingSetResult.ItemTotal=='0.00')}">
                                <fa-icon [icon]="d.faCartArrowDown"></fa-icon>
                                Update Cart
                            </button>

                        </td>
                    </tr>
                </table>

            </td>
        </tr>
    </table>
    <!--Cutlist:<input [(ngModel)]="panel.metadata.gauge.Gauge">-->

</div>
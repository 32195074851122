import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-configure',
  templateUrl: './configure.component.html',
  //styleUrls: ['./configure.component.scss']
})
export class ConfigureComponent implements OnInit {

  constructor(public d: DataService, private router: Router, private route: ActivatedRoute) {
    this.panel = {
      ColorCode: "", //pricingPayload.Colorcode,
      CutlistProvide: "Y",
      DownhillSlope: "",
      Gauge: "", //pricingPayload.Gauge,
      Grade: "",
      Itemnumber: "", //will be set on SalesOrder CREATE
      Materialnumber: "", //pricingPayload.Materialnumber,
      Metal: "", //ZNAL? not sure why we don't have this
      OfferingID: "", //OfferingID,
      PackagingType: "", //pricingPayload.PackagingType,
      PaintSystem: "", //pricingPayload.PaintSystem,
      Plant: "", //pricingPayload.ShipPlant,
      Product: "", //pricingPayload.Materialnumber,
      Productname: "", //this.panel.NAME,
      Quantity001: "", //pricingPayload.ReqQty,
      Requesteddelivery: "", //pricingSetResult.DeliveryDate,
      Unitofmeasure: "", //pricingSetResult.Unitofmeasure,
      UphillSlope: "",
      metadata: {
        product: {},
        color: {},
        gauge: {},
        //ImageUrl: "", //cthis.panel.ImageUrl,
        //colorSwatchUrl: "", //cthis.selectedColor.ImageUrl,
        //colorDesc: "", //this.selectedColor.ColorDescription,
        packaging: {},
        pricingPayload: {}, //pricingPayload,
        pricingSetResult: {}, //pricingSetResult,
        cutlist: [
          {
            "Material": "", //this.panel.MATERIAL_NUMBER,
            "Linenumber": 1,
            "Quantity": "0",
            "Feet": "0",
            "Inches": "0",
            "TotalInches": "0", //not part of the SAP model, but Angular needs something to bind to
            "Fractions": "0",
            "Errors": []
          }
        ]
      }
    };

    this.paramsSubscription = this.route.params.subscribe(async (result) => {
      this.panelId = result["id"];

      await this.d.waitDataLoaded(this.d.model, "panels", 10);

      this.panel.metadata.product = this.d.model.panels.find(p => p.OFFERING_ID == this.panelId);
    });
  }



  semaphore: number = 0;
  colors: any;
  gauges: any;
  paramsSubscription: any;
  panelId: string = "";
  panel: any = {};


  ngOnInit() {

    if (this.d.model.targetPanel != null) {
      console.log("Pre-populating cutlist...");
      console.log(this.d.model.targetPanel)
      let cutlist = [];

      this.d.model.targetPanel.CutlistSet.results.forEach((cut, i) => {
        cut.Linenumber = (i + 1);
        cut.Material = this.d.model.targetPanel.Materialnumber;
        cut.Errors = [];
        cut.TotalInches = "0";

        cut.Quantity = this.d.trimLeadingZeros(cut.Quantity);
        cut.Feet = this.d.trimLeadingZeros(cut.Feet);
        cut.Inches = this.d.trimLeadingZeros(cut.Inches);
        cut.Fractions = this.d.trimLeadingZeros(cut.Fractions);
        cut.TotalInches = this.d.trimLeadingZeros("0" + ((parseInt(cut.Feet, 10) * 12) + parseInt(cut.Inches, 10)));
        delete cut.BundlingNote;
        delete cut.Cutlistnum;
        delete cut.Hash;
        delete cut.Notes;
        delete cut.Salesordernumber;

        cutlist.push(cut);
      });

      //console.dir(cutlist);
      this.panel.metadata.cutlist = cutlist;

      /*
            console.warn("panel.metadata.Ctwt:", this.panel.metadata.Ctwt);
            console.warn("d.model.targetPanel.Ctwt:", this.d.model.targetPanel.Ctwt);
            if (this.panel.metadata.Ctwt === undefined && this.d.model.targetPanel.Ctwt !== undefined) {
              console.warn("Now seems like a good time to fix Ctwt");
              this.panel.metadata.Ctwt = this.d.model.targetPanel.Ctwt;
            } else {
              console.warn("WHERE IS CTWT?");
      
            }
      */
      this.semaphore += 1;
      //console.dir(this.panel.metadata.cutlist);

    }

  }

  goTrims() {
    this.fetchBasecolorIdAndGo('/trims');
  }

  goAccessories() {
    this.fetchBasecolorIdAndGo('/accessories');
  }

  async fetchBasecolorIdAndGo(route) {

    let data = await this.d.inlineGet("/GetBasecolorId?id=" + this.panel.metadata.color.ColorCode);
    this.panel.metadata.color.BasecolorId = data.BasecolorId;
    this.d.model.srcPanel = this.d.deref(this.panel);
    this.router.navigate([route]);
  }
}
